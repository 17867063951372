import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'

import { Card, CardContent, Divider, FormControl, Select, MenuItem } from '@mui/material'

import AuthLayout from 'layouts/auth'
import Seo from 'components/seo'
import SimpleStepper from 'components/stepper/simple'
import Icon from 'components/icon'

import { interestedProductFeatureList, industryList, jobTitleList } from '/static/data/survey.static'

import { sendDataLayerEvent } from 'utils'

const SelectServicePage = ({ setMessageDialogOpen, setErrorMessageI18n }) => {
  const { t, i18n } = useTranslation()

  const jwtToken = Cookies.get('jwtToken')

  const customerId = Cookies.get('customerId')
  const organizationId = Cookies.get('organizationId')

  const [industry, setIndustry] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [interestedProduct, setInterestedProduct] = useState('')
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: ''
  })

  const handleChangeIndustry = (event) => {
    setIndustry(event.target.value)
  }

  const handleChangeJobTitle = (event) => {
    setJobTitle(event.target.value)
  }

  const steps = ['onBoardingSteps.0', 'onBoardingSteps.1', 'onBoardingSteps.2']

  const CustomTransComponents = (text) => ({
    b: <span className="font-bold">{text}</span>,
    h: <span className="text-blue-200">{text}</span>
  })

  const handleGetCustomInfo = async () => {
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL

      const response = await fetch(`${apiUrl}/account/v2/customer/${customerId}/info/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })

      const json = await response.json()
      const { firstName, lastName } = json.data
      setUserData({
        firstName,
        lastName
      })

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }
    } catch (error) {
      const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
        ? `errorMessages.${error?.response?.code}`
        : 'errorMessages.getCustomInfo'
      setErrorMessageI18n(errorMessage)
      setMessageDialogOpen(true)
    }
  }

  const handleUpdateServiceData = async () => {
    setIsActionLoading(true)
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL

      const payload = {
        organizationIndustry: industry,
        organizationJobTitle: jobTitle,
        organizationMasterApp: interestedProduct
      }

      const response = await fetch(`${apiUrl}/account/v2/organization/${organizationId}/profile/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      const json = await response.json()

      const eventPayload = {
        event: 'select_service',
        service_name: interestedProduct,
        industry: industry,
        role: jobTitle
      }

      sendDataLayerEvent(eventPayload)

      navigate('/start-trial/')
      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }
    } catch (error) {
      const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
        ? `errorMessages.${error?.response?.code}`
        : 'errorMessages.updateServiceData'
      setErrorMessageI18n(errorMessage)
      setMessageDialogOpen(true)
    } finally {
      setIsActionLoading(false)
    }
  }

  useLayoutEffect(() => {
    handleGetCustomInfo()
  }, [])

  useEffect(() => {
    if (interestedProduct && industry && jobTitle) {
      setIsBtnDisabled(false)
    }
  }, [interestedProduct, industry, jobTitle])

  return (
    <AuthLayout>
      {jwtToken ? (
        <React.Fragment>
          <Seo
            seo={{
              metaTitle: 'Select service | Mlytics',
              metaDescription:
                'Please choose the product you want to explore and your industry for a tailored experience.',
              metaUrl: 'https://www.mlytics.com/select-service/',
              shareImage: {
                imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
                imageWidth: 1200,
                imageHeight: 630,
                imageType: 'image/jpeg'
              }
            }}
          />

          <section className="relative container">
            <SimpleStepper
              steps={steps}
              className="py-10 min-w-fit max-w-[600px] mx-auto"
              currentStepIndex={1}
              orientation="horizontal"
            />
          </section>

          <section className="relative container xl:px-20 xl:py-5">
            <Card
              className="p-5 lg:p-10"
              sx={{
                boxShadow: '0px 8px 36px 0px rgba(0,0,0, 0.1)',
                borderRadius: '12px'
              }}
            >
              <p className="text-gray-800">
                <Trans
                  i18nKey="selectServicePage.welcomeText.0"
                  components={CustomTransComponents(`${userData.firstName} ${userData.lastName}`)}
                />
              </p>
              <p className="text-gray-800">
                <Trans i18nKey="selectServicePage.welcomeText.1" components={CustomTransComponents()} />
              </p>
              <p className="text-gray-800">
                <Trans i18nKey="selectServicePage.welcomeText.2" components={CustomTransComponents()} />
              </p>
              <div className="mt-7 flex flex-col lg:flex-row w-full min-h-[220px] justify-between">
                {/* select interested product */}
                <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-5">
                  {interestedProductFeatureList.map((product, productIndex) => (
                    <Card
                      key={productIndex}
                      className="cursor-pointer"
                      sx={{
                        boxShadow: `${
                          interestedProduct === product.value ? '0px 8px 28px 0px rgba(44, 94, 99, 0.20)' : 'none'
                        }`,
                        border: `2px solid ${interestedProduct === product.value ? '#21838D' : '#9CB1B4'}`,
                        minHeight: '220px',
                        borderRadius: '12px'
                      }}
                      onClick={() => setInterestedProduct(product.value)}
                    >
                      <CardContent
                        sx={{
                          padding: '20px'
                        }}
                      >
                        <div className="mb-2 flex items-center">
                          {interestedProduct === product.value ? (
                            <Icon className="mr-2 text-blue-200" fontSize="28px" name="check_circle" iconStyle="" />
                          ) : (
                            <Icon className="mr-2 text-gray-700" fontSize="28px" name="circle" />
                          )}
                          <h3 className={`${interestedProduct === product.value ? 'text-blue-200' : 'text-gray-700'}`}>
                            {t(product.name)}
                          </h3>
                        </div>
                        <p
                          className={`my-3 ${interestedProduct === product.value ? 'text-gray-700' : 'text-gray-400'}`}
                        >
                          {t('selectServicePage.productWelcomeText')}
                        </p>
                        {product.features.map((feature, featureIndex) => (
                          <div
                            key={featureIndex}
                            className={`mb-2 text-${
                              interestedProduct === product.value ? 'gray-700' : 'blue-50'
                            } flex items-start`}
                          >
                            <Icon className="mr-2" fontSize="20px" name="check" />
                            <span>{t(feature)}</span>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  ))}
                </div>
                <Divider
                  className="hidden lg:block text-gray-700"
                  orientation="vertical"
                  textAlign="center"
                  sx={{
                    margin: 'auto 28px',
                    height: '250px'
                  }}
                />
                {/* select industry, job title */}
                <div className="mt-5 lg:mt-0 flex-0 min-w-[250px] max-w-full flex flex-col justify-between">
                  <div>
                    <div className="text-gray-800">{t('selectServicePage.industryList.title')}</div>
                    <FormControl
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{
                        marginBottom: '12px'
                      }}
                    >
                      <Select
                        value={industry}
                        label=""
                        onChange={handleChangeIndustry}
                        displayEmpty
                        renderValue={
                          industry !== ''
                            ? undefined
                            : () => <div className="text-gray-400"> {t('form.select.placeholder')} </div>
                        }
                      >
                        {industryList.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {t(item.text)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="text-gray-800">{t('selectServicePage.jobTitleList.title')}</div>
                    <FormControl
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{
                        marginBottom: '12px'
                      }}
                    >
                      <Select
                        value={jobTitle}
                        label=""
                        onChange={handleChangeJobTitle}
                        displayEmpty
                        renderValue={
                          jobTitle !== ''
                            ? undefined
                            : () => <div className="text-gray-400"> {t('form.select.placeholder')} </div>
                        }
                      >
                        {jobTitleList.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {t(item.text)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <button
                    className={`btn w-full btn-blue btn-xs disabled:bg-gray-400 disabled:border-gray-400 ${
                      isActionLoading ? 'disabled' : ''
                    }`}
                    disabled={isBtnDisabled || isActionLoading}
                    onClick={handleUpdateServiceData}
                  >
                    {isActionLoading ? (
                      <svg
                        className="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-r-gray-50 border-t-gray-50"
                        style={{
                          width: '24px',
                          height: '24px'
                        }}
                        viewBox="0 0 32 32"
                      ></svg>
                    ) : (
                      <span>{t('button.startService')}</span>
                    )}
                  </button>
                </div>
              </div>
            </Card>
          </section>
        </React.Fragment>
      ) : (
        ''
      )}
    </AuthLayout>
  )
}

SelectServicePage.propTypes = {
  setMessageDialogOpen: PropTypes.func,
  setErrorMessageI18n: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  setMessageDialogOpen: (isOpen) => dispatch({ type: 'SET_MESSAGE_DIALOG_OPEN', payload: isOpen }),
  setErrorMessageI18n: (i18nKey) => dispatch({ type: 'SET_ERROR_MESSAGE_I18N_KEY', payload: i18nKey })
})

export default connect(null, mapDispatchToProps)(SelectServicePage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
