const interestedProductFeatureList = [
  {
    name: 'navigation.category2.list1.name',
    value: 'stream',
    features: [
      'selectServicePage.streamFeatureList.0',
      'selectServicePage.streamFeatureList.1',
      'selectServicePage.streamFeatureList.2',
      'selectServicePage.streamFeatureList.3'
    ]
  },
  {
    name: 'navigation.category2.list2.name',
    value: 'web',
    features: [
      'selectServicePage.webFeatureList.0',
      'selectServicePage.webFeatureList.1',
      'selectServicePage.webFeatureList.2',
      'selectServicePage.webFeatureList.3'
    ]
  }
]

const industryList = [
  { text: 'selectServicePage.industryList.items.0', value: 'Advertising' },
  { text: 'selectServicePage.industryList.items.1', value: 'Design' },
  { text: 'selectServicePage.industryList.items.2', value: 'Financial services' },
  { text: 'selectServicePage.industryList.items.3', value: 'Education & training' },
  { text: 'selectServicePage.industryList.items.4', value: 'eCommerce' },
  { text: 'selectServicePage.industryList.items.5', value: 'Gaming & entertainment' },
  { text: 'selectServicePage.industryList.items.6', value: 'Government' },
  { text: 'selectServicePage.industryList.items.7', value: 'Healthcare & medical' },
  { text: 'selectServicePage.industryList.items.8', value: 'Technology' },
  { text: 'selectServicePage.industryList.items.9', value: 'Travel' },
  { text: 'selectServicePage.industryList.items.10', value: 'Multimedia' },
  { text: 'selectServicePage.industryList.items.11', value: 'Manufacturing' },
  { text: 'selectServicePage.industryList.items.12', value: 'Telco' },
  { text: 'selectServicePage.industryList.items.13', value: 'Other' }
]

const jobTitleList = [
  { text: 'selectServicePage.jobTitleList.items.0', value: 'Consultant' },
  { text: 'selectServicePage.jobTitleList.items.1', value: 'Marketing/PR Manager' },
  { text: 'selectServicePage.jobTitleList.items.2', value: 'Executive/Director' },
  { text: 'selectServicePage.jobTitleList.items.3', value: 'IT/Engineering' },
  { text: 'selectServicePage.jobTitleList.items.4', value: 'Designer' },
  { text: 'selectServicePage.jobTitleList.items.5', value: 'Product Manager' },
  { text: 'selectServicePage.jobTitleList.items.6', value: 'Project/Program Manager' },
  { text: 'selectServicePage.jobTitleList.items.7', value: 'HR/Recruiting/Operations' },
  { text: 'selectServicePage.jobTitleList.items.8', value: 'Training & Development' },
  { text: 'selectServicePage.jobTitleList.items.9', value: 'Sales/Account Manager' },
  { text: 'selectServicePage.jobTitleList.items.10', value: 'Analytics' },
  { text: 'selectServicePage.jobTitleList.items.11', value: 'Other' }
]

export { interestedProductFeatureList, industryList, jobTitleList }
